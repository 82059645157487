import throttle from './throttle';
import windowPopup from './windowPopup';
import stickybits from 'stickybits';
import './stickybits.css';
const $ = window.jQuery;
const $window = $(window);
const $document = $(document);
const $height = $document.height();

function cleanUpIframes() {
  // This removes all height/width from iframes
  const $iframes = $('iframe');

  // Find &#x26; save the aspect ratio for all iframes
  $iframes.each(function() {
    $(this)
      .data('ratio', this.height / this.width)
      // Remove the hardcoded width &#x26; height attributes
      .removeAttr('width')
      .removeAttr('height');
  });
  // Resize the iframes when the window is resized
  $window
    .resize(function() {
      $iframes.each(function() {
        // Get the parent container&#x27;s width
        var width = $(this)
          .parent()
          .width();
        $(this)
          .width(width)
          .height(width * $(this).data('ratio'));
      });
      // Resize to fix all iframes on page load.
    })
    .resize();
}

export default {
  init() {
    cleanUpIframes();

    stickybits('.child', {
      useStickyClasses: true,
      stickyBitStickyOffset: 300,
    });

    $('.js-social-share').on('click', function(e) {
      e.preventDefault();
      windowPopup($(this).attr('href'), 500, 300);
    });

    const getMax = () => {
      return $height - $window.height();
    };

    const getValue = () => {
      return $window.scrollTop();
    };

    if ('max' in document.createElement('progress')) {
      // Browser supports progress element
      var progressBar = $('progress');

      // Set the Max attr for the first time
      progressBar.attr({ max: getMax() });

      $document.on('scroll', () => {
        // On scroll only Value attr needs to be calculated
        progressBar.attr({ value: getValue() });
      });

      $window.resize(() => {
        // On resize, both Max/Value attr needs to be calculated
        progressBar.attr({ max: getMax(), value: getValue() });
      });
    } else {
      const progressBar = $('.progress-bar');
      let max = getMax();
      let value;
      let width;

      const getWidth = () => {
        // Calculate width in percentage
        value = getValue();
        width = (value / max) * 100;
        width = width + '%';
        return width;
      };

      const setWidth = () => {
        progressBar.css({ width: getWidth() });
      };

      $document.on('scroll', setWidth);
      $window.on('resize', () => {
        // Need to reset the Max attr
        max = getMax();
        setWidth();
      });
    }
  },
  finalize() {},
};
