import './external/rAF';
import './external/jquery.particleground';
import './styles/style.scss';
import './styles/tailwind.css';

import Router from './util/Router';
import ces2016 from './routes/ces2016';
import ces2017 from './routes/ces2017';
import ces2018 from './routes/ces2018';
import common from './routes/common';
import events from './routes/events';
import home from './routes/home';
import production from './routes/production';
import singlePost from './routes/single/singlePost';
import pageTemplateTemplateFlexible from './routes/pageTemplateTemplateFlexible';

const $ = window.jQuery;

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  common,
  home,
  events,
  production,
  ces2016,
  ces2017,
  ces2018,
  singlePost,
  pageTemplateTemplateFlexible,
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
