const $ = window.jQuery;

export default {
  init: function() {
    // JavaScript to be fired on the events page
    $('#particles-events').particleground({
      dotColor: 'rgba(0,119, 2238, .7 )',
      lineColor: 'rgba(0,119, 2238, .5 )',
      particleRadius: 4,
      lineWidth: 1,
      direction: 'up',
      proximity: 150,
    });
  },
  finalize: function() {
    // JavaScript to be fired on the events page, after the init JS
  },
};
