import { styler, chain, delay, tween } from 'popmotion';

export default {
  init: function() {
    const heroImage = document.getElementsByClassName('uber-image')[0];
    const setClipWidth = width => {
      if (!heroImage) {
        return;
      }
      heroImage.style.clip = `rect(auto, ${width}, auto, auto)`;
    };

    setClipWidth(window.innerWidth + 'px');

    setTimeout(() => {
      setClipWidth('auto');
    }, 2000);

    document.querySelectorAll('.animated-heading').forEach((el, i) => {
      el.style.transform = 'translateY(-5px)';
      el.style.transitionDelay = i * 75 + 'ms';
    });

    const path = document.getElementById('wiggle');
    const pathStyler = styler(path);
    pathStyler.set('opacity', 0);
    chain(
      delay(1000),
      tween({
        from: { pathLength: 0, opacity: 0 },
        to: { pathLength: 1, opacity: 1 },
      })
    ).start(v => pathStyler.set(v));
  },
  finalize: function() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
