const $ = window.jQuery;

export default {
  init: function() {
    // JavaScript to be fired on the production page
    $('#particles-home').particleground({
      dotColor: '#eee',
      lineColor: 'rgba(0,0,0,.1)',
      particleRadius: 4,
      lineWidth: 1,
      direction: 'up',
      proximity: 150,
    });
  },
  finalize: function() {
    // JavaScript to be fired on the production page, after the init JS
  },
};
