import '../external/jquery.unveil';

import { setupSearch, setupMobileNav } from '../util/navigation';

const $ = window.jQuery;

export default {
  init: function() {
    // Handles the navigation
    setupMobileNav();
    setupSearch();
    $('.event-item-info').on('click', function() {
      $(this)
        .children('.details')
        .slideToggle(200);
    });
    $('.lazy').unveil(200, function() {
      $(this).load(function() {
        this.style.opacity = 1;
      });
    });
  },
  finalize: function() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
