import {
  tween,
  styler,
  spring,
  stagger,
  easing,
  chain,
  value,
  delay,
  schedule,
  everyFrame,
  transform,
  listen,
  calc,
} from 'popmotion';
import './navigation.css';

function lockBodyScroll() {
  document.body.classList.add('scroll-frozen');
}

function unlockBodyScroll() {
  document.body.classList.remove('scroll-frozen');
}

function isDesktop() {
  const MqL = 1025;
  //check window width (scrollbar included)
  var e = window,
    a = 'inner';
  if (!('innerWidth' in window)) {
    a = 'client';
    e = document.documentElement || document.body;
  }
  if (e[a + 'Width'] >= MqL) {
    return true;
  } else {
    return false;
  }
}
export function setupSearch() {
  const $searchBtn = document.getElementById('search-button');
  const $searchForm = document.getElementById('search');
  const $searchOverlay = document.getElementById('search-overlay');
  const $searchInput = document.getElementById('search-input');

  function isSearchOpen() {
    return $searchBtn.classList.contains('search-open');
  }

  function closeSearch() {
    $searchBtn.setAttribute('aria-expanded', false);
    $searchBtn.classList.remove('search-open');
    unlockBodyScroll();
    $searchForm.classList.add('tw-hidden');
    $searchOverlay.classList.add('tw-hidden');
    $searchOverlay.style.pointerEvents = 'none';
    $searchBtn.focus();
    window.removeEventListener('keydown', handleEscapeKeyPress);
    $searchOverlay.removeEventListener('click', handleOverlayClick);
  }

  function handleEscapeKeyPress(event) {
    if (event.code === 'Escape' && isSearchOpen()) {
      closeSearch();
    }
  }

  function handleOverlayClick() {
    if (isSearchOpen()) {
      closeSearch();
    }
  }

  function openSearch() {
    $searchBtn.setAttribute('aria-expanded', true);
    $searchBtn.classList.add('search-open');
    $searchForm.classList.remove('tw-hidden');
    $searchOverlay.classList.remove('tw-hidden');
    $searchOverlay.style.pointerEvents = 'auto';
    $searchInput.focus();
    lockBodyScroll();
    window.addEventListener('keydown', handleEscapeKeyPress);
    $searchOverlay.addEventListener('click', handleOverlayClick);
  }

  $searchBtn.addEventListener('click', event => {
    if (isSearchOpen()) {
      closeSearch();
    } else {
      openSearch();
    }
  });

  function maybeForceSearchClose() {
    if (!isSearchOpen()) {
      return;
    }

    if (!isDesktop()) {
      closeSearch();
    }
  }

  function handleResize() {
    !window.requestAnimationFrame
      ? setTimeout(maybeForceSearchClose, 300)
      : window.requestAnimationFrame(maybeForceSearchClose);
  }

  window.addEventListener('resize', handleResize);
}

export function setupMobileNav() {
  // Handles the navigation
  const $btn = document.getElementById('menu-button');
  const $menu = document.getElementById('menu');
  const styledMenu = styler($menu);
  const $menuLinks = document.querySelectorAll('.menu-link');
  const styledMenuLinks = Array.from($menuLinks).map(styler);
  const styledMenuLinksInverse = Array.from($menuLinks)
    .reverse()
    .map(styler);
  const allBackgroundElements = document.querySelectorAll(
    '.backgroundElement, [tabindex="0"]'
  );

  const isOpen = () => $btn.classList.contains('menu-button--open');

  function moveNav() {
    if (!isOpen()) {
      return;
    }

    if (isDesktop()) {
      close();
    }
  }

  function handleResize() {
    !window.requestAnimationFrame
      ? setTimeout(moveNav, 300)
      : window.requestAnimationFrame(moveNav);
  }

  function close() {
    $btn.classList.remove('menu-button--open');
    $btn.setAttribute('aria-expanded', false);
    unlockBodyScroll();
    stagger(
      Array(styledMenuLinks.length).fill(
        spring({
          from: { y: '0px', opacity: 1 },
          to: { y: '-50px', opacity: 0 },
          stiffness: 300,
          damping: 35,
        })
      ),
      50
    ).start(v => {
      v.forEach((pos, i) => {
        styledMenuLinks[i].set(pos);
      });
    });

    chain(
      delay(400),
      spring({
        from: { y: '0%' },
        to: { y: '-100%' },
        stiffness: 300,
        damping: 200,
      })
    ).start(v => styledMenu.set(v));
    $btn.focus();
    window.removeEventListener('resize', handleResize);
  }

  function open() {
    $btn.classList.add('menu-button--open');
    $btn.setAttribute('aria-expanded', true);
    lockBodyScroll();
    spring({
      to: { y: '0%', opacity: 1 },
      from: { y: '-100%', opacity: 0 },
      stiffness: 200,
      damping: 30,
    }).start(v => styledMenu.set(v));

    stagger(
      Array(styledMenuLinks.length).fill(
        spring({
          from: { y: '-30px', opacity: 0 },
          to: { y: '0px', opacity: 1 },
          stiffness: 400,
          damping: 40,
        })
      ),
      100
    ).start(v =>
      v.forEach((pos, i) => {
        styledMenuLinksInverse[i].set(pos);
      })
    );
    $btn.focus();
    window.addEventListener('resize', handleResize);
  }

  function toggle() {
    if (isOpen()) {
      close();
    } else {
      open();
    }
  }

  function handleEscapeKeyWhenMenuOpen(event) {
    if (event.code === 'Escape' && isOpen()) {
      close();
    }
  }

  window.addEventListener('keydown', handleEscapeKeyWhenMenuOpen);
  $btn.addEventListener('click', toggle);
}
